import React from 'react'
import styled from 'styled-components'

import SEO from '@/components/seo'
import CalculatorStructure from '@/components/CalculatorStructure'
import { Stripe } from '@/components/Calculator/Ecommerce'

import { CALCULATORS } from '@/helpers/mocks/calculators'
import SectionInfoCard from './SectionInfoCard'

const StyledStripeCalculator = styled(Stripe)`
  min-width: unset;
  max-width: unset;
`

const StripeCalculator = () => (
  <>
    <SEO title="Stripe" />
    <CalculatorStructure
      section="finance-calculators"
      headerTitle="finance"
      title={CALCULATORS['finance-calculators'].stripe.title}
      description={CALCULATORS['finance-calculators'].stripe.description}
      calculatorNode={<StyledStripeCalculator />}
      asideCards={<SectionInfoCard />}
    />
  </>
)

export default StripeCalculator
